
// import { getActivityInfo, getActivitySaleInfo } from 'editOredit/api'
import { getYxDetail } from './api.js'
export default {
  name: "",
  data() {
    return {
      breadcrumbData: [{
        path: "/index",
        name: "index",
        title: "首页"
      },
      {
        path: "/activityConfigurationList",
        name: "activityConfigurationList",
        title: "营销活动管理"
      },
      {
        path: "/activityConfigurationList/openView",
        name: "openView",
        title: "查看详情"
      }
      ],
      pageLoadFlag: false,
      detilInfo: {
      },
      columns: [],
      tableData: [],
      fxcolumns: [
        {
          title: "客户名称",
          dataIndex: "custInfoName",
        },
        {
          title: "渠道范围",
          dataIndex: "channelName",
        },
      ],
      fxData: [],
      directColumn: [
        {
          title: "产品型号*",
          dataIndex: "productInfoZzprdmodel",

          scopedSlots: { customRender: 'productInfoZzprdmodel' },
        },

        {
          title: "提货价",
          dataIndex: "standardPrice",

          scopedSlots: { customRender: 'standardPrice' },
        },
        {
          title: "数量*",
          dataIndex: "qty",

          scopedSlots: { customRender: 'qty' },
        },
        {
          title: "开票单价*",
          dataIndex: "billPrice",

          scopedSlots: { customRender: 'billPrice' },
        },
        {
          title: "单个商家限量",
          dataIndex: "purchaseLimitQty",

          scopedSlots: { customRender: 'purchaseLimitQty' },
        },

        {

          title: "本次活动返利",
          dataIndex: "rebateMoney",

          scopedSlots: { customRender: 'rebateMoney' },
        },

        {
          title: "活动说明",
          dataIndex: "productSubtitle",

          scopedSlots: { customRender: 'productSubtitle' },
        },
        {
          title: "备注",
          dataIndex: "remark",

          scopedSlots: { customRender: 'remark' },
        },

      ],
      arbitrageColumn: [
        {
          title: "组合编码*",
          dataIndex: "packageCode",

          scopedSlots: { customRender: 'packageCode' },
        },
        {
          title: "产品型号*",
          dataIndex: "productInfoZzprdmodel",

          scopedSlots: { customRender: 'productInfoZzprdmodel' },
        },

        {
          title: "提货价",
          dataIndex: "standardPrice",

          scopedSlots: { customRender: 'standardPrice' },
        },
        {
          title: "数量*",
          dataIndex: "qty",

          scopedSlots: { customRender: 'qty' },
        },
        {
          title: "开票单价*",
          dataIndex: "billPrice",

          scopedSlots: { customRender: 'billPrice' },
        },
        {
          title: "组合数量*",
          dataIndex: "packageNum",

          scopedSlots: { customRender: 'packageNum' }
        },
        {
          title: "单个商家限量",
          dataIndex: "purchaseLimitQty",

          scopedSlots: { customRender: 'purchaseLimitQty' },
        },

        {

          title: "本次活动返利",
          dataIndex: "rebateMoney",

          scopedSlots: { customRender: 'rebateMoney' },
        },

        {
          title: "活动说明",
          dataIndex: "productSubtitle",

          scopedSlots: { customRender: 'productSubtitle' },
        },
        {
          title: "备注",
          dataIndex: "remark",

          scopedSlots: { customRender: 'remark' },
        },

      ],
      combinationColumn: [

        {
          title: "组合编码*",
          dataIndex: "packageCode",

          scopedSlots: { customRender: 'packageCode' },
        },
        {
          title: "产品分组编码*",
          dataIndex: "productGroup",

          scopedSlots: { customRender: 'productGroup' },
        },
        {
          title: "产品分组描述*",
          dataIndex: "productGroupRemark",

          scopedSlots: { customRender: 'productGroupRemark' },
        },
        {
          title: "产品型号*",
          dataIndex: "productInfoZzprdmodel",

          scopedSlots: { customRender: 'productInfoZzprdmodel' },
        },

        {
          title: "提货价",
          dataIndex: "standardPrice",

          scopedSlots: { customRender: 'standardPrice' },
        },
        {
          title: "数量*",
          dataIndex: "qty",

          scopedSlots: { customRender: 'qty' },
        },
        {
          title: "开票单价*",
          dataIndex: "billPrice",

          scopedSlots: { customRender: 'billPrice' },
        },
        {
          title: "组合数量*",
          dataIndex: "packageNum",

          scopedSlots: { customRender: 'packageNum' }
        },
        {
          title: "单个商家限量",
          dataIndex: "purchaseLimitQty",

          scopedSlots: { customRender: 'purchaseLimitQty' },
        },
        {

          title: "本次活动返利",
          dataIndex: "rebateMoney",

          scopedSlots: { customRender: 'rebateMoney' },
        },

        {
          title: "是否默认*",
          dataIndex: "defaultFlagName",

          scopedSlots: { customRender: 'yesOrno' },
        },
        {
          title: "活动说明",
          dataIndex: "productSubtitle",

          scopedSlots: { customRender: 'productSubtitle' },
        },
        {
          title: "备注",
          dataIndex: "remark",

          scopedSlots: { customRender: 'remark' },
        },

      ],
      custTagName: []
    };
  },


  mounted() {

    if (this.$route.query.id) {
      this.getDetailInfo(this.$route.query.id)

    } else {
      this.$message.warning('获取详情失败')
    }
  },

  methods: {
    // getOwner(id) {
    //   let data = {
    //     id: id
    //   }
    //   getActivitySaleInfo(data).then(res => {
    //     if (res && res.data.code == 0) {
    //       this.tableData = res.data.data
    //     } else {
    //       this.$message.warning('获取数据失败', res.data.msg)
    //     }
    //   })
    // },
    // 下载
    handleRemove(item) {
      window.open(item.url, '_blank')
    },
    // 获取详情
    getDetailInfo(aid) {
      let data = { id: aid }
      this.pageLoadFlag = true
      getYxDetail(data).then(res => {
        this.detilInfo = res.data
        this.tableData = res.data.productDtoList
        let custTagName = []
        this.fxData = this.detilInfo.custDtoList
        if (this.detilInfo.custTag ) {
          this.detilInfo.custTag.forEach(element => {
            element = element.split(",")
            element.forEach(item => {
              if (item == '14182308914') {
                custTagName.push('专卖店商家')
              }
              if (item == '1') {
                custTagName.push('分销商')
              }
              if (item == '2') {
                custTagName.push('指定分销商')

              }
            })
            this.custTagName = custTagName
          });
        }

        if (this.detilInfo.discountTypeName == '直降' || this.detilInfo.discountTypeName == '后返') {
          this.columns = this.directColumn
        }
        else if (this.detilInfo.discountTypeName == '套购') {
          this.columns = this.arbitrageColumn
        }
        else {
          this.columns = this.combinationColumn
        }
        this.pageLoadFlag = false
      })
    },
    goback() {
      window.close()
      this.$router.push({ path: '/activityConfigurationList' });
    }
  }
};